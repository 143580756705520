<template>
  <div>

    <div>
      <!-- button -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        @click="downloadFile()"
      >
        Télécharger le Manuel au format PDF
      </b-button>

      <!-- for print pdf -->
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="10"
        :filename="'Manuel d utilsation de la plateforme SIGES'"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        class="d-content"
        @progress="onProgress($event)"
        @hasDownloaded="onDownloaded($event)"
      >
        <section slot="pdf-content">
          <!-- start real content -->
          <b-col
            cols="12"
            xl="12"
            md="12"
            class=""
            style="font-size: 10px"
          >
            <ManuelComponent />
          </b-col>
          <!-- end real content -->
        </section>
      </vue-html2pdf>

      <!-- current content -->
      <!-- <ManuelComponent /> -->
    </div>

    <hr>
    <hr>
    <hr>

    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/analyse-roi-campagnes-e-mailing-keyzz-seo__phfau5.jpg')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-white">
            Puis-je vous aider ?
          </h2>
          <b-card-text class="mb-2 text-white">
            <strong> Trouvez rapidement ce que vous cherchez ! </strong>
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Rechercher dans le manuel d'utlisation..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>

    <!-- <section>
      <b-card>
        <h1 class="text-center">
          MTCA
        </h1>
      </b-card>
    </section> -->

    <section>
      <!-- <b-row> -->
      <!-- Grand public -->
      <!-- <b-col class="md-6">
          <b-card>
            <b-card-header>
              <b-card-title>
                <h2>Grand Public</h2>
              </b-card-title>
            </b-card-header>
            <hr>
            <b-card-body>
              <ul>
                <li>
                  <b-link>
                    <u>Description de l'utilisateur</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Inscription</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Connexion</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Ressources (Menu Formulaire)</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Remplissage des formulaires</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Statistiques</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Dashboard</u>
                  </b-link>
                </li>
              </ul>
            </b-card-body>
          </b-card>
        </b-col> -->

      <!-- Promoteur d'établissement -->
      <!-- <b-col class="md-6">
          <b-card>
            <b-card-header>
              <b-card-title>
                <h2>Promoteur d'établissement</h2>
              </b-card-title>
            </b-card-header>
            <hr>
            <b-card-body>
              <ul>
                <li>
                  <b-link>
                    <u>Description de l'utilisateur</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Inscription</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Connexion</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Création des comptes agents</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Ressources (Menu Formulaire)</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Remplissage et validation des formulaires</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Statistiques</u>
                  </b-link>
                </li>
                <li>
                  <b-link>
                    <u>Dashboard</u>
                  </b-link>
                </li>
              </ul>
            </b-card-body>
          </b-card>
        </b-col> -->
      <!-- </b-row> -->
      <b-row>
        <b-col
          v-for="(card, cardIndex) in filteredItems"
          :key="cardIndex"
          md="6"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                <h2>{{ card.title }}</h2>
              </b-card-title>
            </b-card-header>
            <hr>
            <b-card-body>
              <ul
                v-for="(link, linkKey) in card.link"
                :key="linkKey"
              >
                <!-- <ul> -->
                <!-- <router-link :to="name: link.path"> -->
                <li>
                  <b-link :href="link.path">
                    <u>{{ link.title }}</u>
                  </b-link>
                </li>
                <!-- </router-link> -->
                <!-- </ul> -->
              </ul>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <!-- <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        payment tab
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
        >

          title
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        / payment tab

        sitting lady image
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        / sitting lady image
      </b-tabs>
    </section> -->
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <!-- <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>+ (810) 2548 2568</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>hello@help.com</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section> -->
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BInputGroup,
  BLink,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import ManuelComponent from '@/components/ManuelComponent.vue'
import VueHtml2pdf from 'vue-html2pdf'
import Ripple from 'vue-ripple-directive'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BLink,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BButton,

    FaqQuestionAnswer,
    ManuelComponent,
    VueHtml2pdf,
  },
  directives: {
    Ripple,
    // 'b-toggle': VBToggle,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
      filteredItems: [],
      cards: [
        // Promoteur d'établissment
        {
          title: "Promoteur d'établissement",
          link: [
            {
              title: 'Comment inscrire mon établissement ?',
              path: '/manuel-utilisation/promoteurEtablissement/inscription',
            },
            {
              title: 'Comment créer le compte pour les agents de mon établissement ?',
              path: '/manuel-utilisation/promoteurEtablissement/compte-agent-etablissement',
            },
            {
              title: "Comment remplir la fiche d'enregistrement des clients dans les hôtels et établissements d'hébergement assimilés ?",
              path: '/manuel-utilisation/promoteurEtablissement/enregistrement-client-hotel',
            },
            {
              title: "Comment remplir la fiche statistique mensuelle sur l'exploitation des hôtels et établissements d'hébergement assimilés ?",
              path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueExploitationHotel',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de voyage ?',
              path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceVoyage',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de transport de voyageurs ?',
              path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceTransportVoyageur',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de location de véhicules sans opérateur ?',
              path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceLocationVehiculeSansOperateur',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des établissements de restaurants, bars et débit de boisson  ?',
              path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueEtablissementRestauBarDebit',
            },
          ],
        },

        // Agent d'établissment
        {
          title: "Agent d'établissement",
          link: [
            {
              title: "Comment remplir la fiche d'enregistrement des clients dans les hôtels et établissements d'hébergement assimilés ?",
              path: '/manuel-utilisation/agent-etablissement/ficheEnregistrementClientHotel',
            },
            {
              title: "Comment remplir la fiche statistique mensuelle sur l'exploitation des hôtels et établissements d'hébergement assimilés ?",
              path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueExploitationHotel',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de voyage ?',
              path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceVoyage',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de transport de voyageur ?',
              path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceTransportVoyageur',
            },
            {
              title: 'Comment remplir la fiche statistique mensuelle des agences de location de véhicules sans opérateur ?',
              path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceLocationVehiculeSansOperateur',
            },
            {
              title: 'Comment remplir fiche statistique mensuelle des établissements de restaurant, bar et débit de boisson  ?',
              path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueEtablissementRestauBarDebit',
            },
          ],
        },

        // Commissariat
        {
          title: 'Commissariat',
          link: [
            {
              title: 'Comment créer les comptes des agents de police ?',
              path: '/manuel-utilisation/commissaire/inscription',
            },
            // {
            //   title: "Comment remplir la fiche de renseignement d'entrée des voyageurs aux postes frontaliers terrestres ?",
            //   path: '/manuel-utilisation/commissaire/ficheEnregistrementEntreeFrontiereTerrestre',
            // },
            // {
            //   title: 'Comment remplir la fiche de renseignement de sortie des voyageurs aux postes frontaliers terrestres ?',
            //   path: '/manuel-utilisation/commissaire/ficheEnregistrementSortieFrontiereTerrestre',
            // },
          ],
        },

        //  Agent Police
        {
          title: 'Agent de Police frontalier',
          link: [
            {
              title: "Comment remplir la fiche de renseignement d'entrée des voyageurs aux postes frontaliers terrestres ?",
              path: '/manuel-utilisation/agent-police/ficheEnregistrementEntreeFrontiereTerrestre',
            },
            {
              title: 'Comment remplir la fiche de renseignement de sortie des voyageurs aux postes frontaliers terrestres ?',
              path: '/manuel-utilisation/agent-police/ficheEnregistrementSortieFrontiereTerrestre',
            },
          ],
        },

        // Agent MTCA
        {
          title: 'Agent MTCA',
          link: [
            {
              title: 'Comment consulter les données statistiques sur la plateforme ?',
              path: '/manuel-utilisation/agent-mtca/donneeStatistique',
            },
          ],
        },

        // Administrateur
        {
          title: 'Administrateur',
          link: [
            {
              title: 'Comment créer et administrer les comptes des Agents MTCA ?',
              path: '/manuel-utilisation/adminstrateur/compteAgentMtca',
            },
            {
              title: 'Comment créer et administrer les comptes des commissariats ?',
              path: '/manuel-utilisation/adminstrateur/compteCommissariat',
            },
            {
              title: "Comment créer et administrer un autre compte d'administrateur  ?",
              path: '/manuel-utilisation/adminstrateur/compteAdministrateur',
            },
            // {
            //   title: 'Comment créer et administrer les comptes des agents de la DEI',
            //   path: '/manuel-utilisation/adminstrateur/compteAgentDei',
            // },
          ],
        },

      ],

      pdf: null,
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler(val, old) {
        const toLower = str => (`${str || ''}`).toLowerCase()
        const query = toLower(val)
        this.filteredItems = this.cards.filter(e => toLower(e.title).indexOf(query) >= 0 || e.link.some(l => toLower(l.title).indexOf(query) >= 0))
          .map(e => {
            e.link = e.link.filter(l => toLower(l.title).indexOf(query) >= 0)
            return e
          })
        this.filteredItems = this.filteredItems.filter(e => true)
      },
    },
  },
  methods: {
    fetchData() {
      this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => {
        this.faqData = res.data
      })
    },

    // ----- download pdf -----
    downloadFile() {
      const fileUrl = '/example/Manuel d utilsation de la plateforme SIGES.pdf'
      const xhr = new XMLHttpRequest()
      xhr.open('GET', fileUrl, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        const blob = xhr.response
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Manuel d utilsation de la plateforme SIGES.pdf')
        document.body.appendChild(link)
        link.click()
      }
      xhr.send()
    },

    // downloadPDF() {
    //   this.$refs.html2Pdf.generatePdf()
    // },
    // onProgress(event) {
    //   console.log(`Processed: ${event} / 100`)
    // },
    // onDownloaded(event) {
    //   // this.pdf = event
    //   // this.pdf = new File([event.Blob], this.validOutput.nomEtablissement+this.validOutput.mois+this.validOutput.annee, { type:"application/pdf" })
    //   this.pdf = event
    //   // console.log('Download ok: ', event, this.pdf)
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
.faq-search{
    background-blend-mode: color;
    background-color: #00000063 !important;
}
</style>
