<template>
  <div>
    <div class="text-center">
      <h1>MANUEL D'UTILISATION</h1> 
    </div>
    <hr>
    <div 
      class="my-2"
      v-for=" faq, indexFaq in faqList" :key="indexFaq"
    >
      <!-- title -->
      <div>
        <h3><strong>{{ faq.titre }}</strong></h3> 
      </div>
      <div>
        <div
          v-html="faq.contenu ? faq.contenu : '' "
        />
      </div>
      
      <!-- Spacer -->
      <hr class="invoice-spacing">
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'


import { mapActions } from 'vuex'
import utilsService from '@/services/utils/utils.service'
import faqStoreModule from '@/store/faq'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
  },
  props: {
    // tableData: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'faq', module: faqStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      faqList: [],

      // currentPage: 1,
      // perPageLocal: this.$props.perPage,
      // perPageValues: [2, 10, 20, 100, 200],
    }
  },
  computed: {
    // tableDataLength() {
    //   return (this.tableData && this.tableData.length) || 0
    // },
    // fromToOver() {
    //   let from = this.currentPage * this.perPageLocal - this.perPageLocal
    //   if (from < 0) from = 0

    //   let to = from + this.perPageLocal
    //   if (to > this.totalRows) to = this.totalRows

    //   if (from === 0) from = 1
    //   if (to === 0) from = 0
    //   return { from, to }
    // },
  },
  watch: {
    // currentPage: {
    //   handler(val, old) {
    //     this.emitPaginationOutput()
    //   },
    // },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    ...mapActions('faq', {
      action_getListFaq: 'getListFaq',
      action_deleteFaq: 'deleteFaq',
    }),
    // ---------------------------------------------------
    fetchData() {
      
      this.action_getListFaq()
        .then(response => {
          // console.log('response: ⭕️ ⭕️ ', response)
          const { data } = response
          const { resources, total } = data
          console.log('resources, total: ⭕️ ', resources, total)
          // filter by user role
          // console.log('this.isSuperAdmin: ', this.isSuperAdmin)

          this.faqList = resources
          console.log('resources: *****************', resources)
          // -------------------------------------------
          // this.paginationData.total = total || res.data.resources.length || 0
          // if (this.paginationData.metaData.from == 0) {
          //   this.paginationData.metaData.from += 1
          // } else {
          //   this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
          //     - this.paginationData.perPage
          // }
          // this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
          //   - this.paginationData.perPage
          //   + total
        })
        .catch(err => {
          this.isLoading = false
          // console.log('error-faq-list', err)
        })



    },

    // emitPaginationOutput() {
    //   this.$emit('change', {
    //     perPage: this.perPageLocal,
    //     currentPage: this.currentPage,
    //   })
    // },
  },
}
</script>

<style scoped lang="scss">
@import "@core/scss/base/bootstrap-extended/include";

ul.pagination.b-pagination {
  margin-bottom: 0;
}
.pagination-ctn {
  @media (max-width: 768px) {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.pagination {
  @media (max-width: 768px) {
    justify-content: center !important;
    width: 100%;
  }
}
</style>
