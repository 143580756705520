import { BaseService } from './base.service'

export class FaqService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  createFaq(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/faqs/create-file', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getListFaq(params) {
    // console.log('params :>> ', params)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/faqs/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getFaq(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/faqs/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  editFaq(object) {
    const { id , data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/faqs/update-file/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  deleteFaq(id) {
    // console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete('/faqs/remove', { data:{id} })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new FaqService()
