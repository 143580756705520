import faqService from '@/services/http/faq.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async createFaq({ rootState }, data) {
      const res = await faqService.createFaq(data)
      return res
    },

    async getListFaq({ rootState }, data) {
      const res = await faqService.getListFaq(data)
      return res
    },

    async getFaq({ rootState }, data) {
      const res = await faqService.getFaq(data)
      return res
    },

    async editFaq({ rootState }, data) {
      const res = await faqService.editFaq(data)
      return res
    },
    
    async deleteFaq({ rootState }, data) {
      const res = await faqService.deleteFaq(data)
      return res
    },
  },
}
